import React, { useContext, useState, useEffect } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { Spin as Hamburger } from 'hamburger-react'
import MenuList from "./MenuList"
import IconsRedes from "../IconRedes"

import { ImPhone } from 'react-icons/im'




const HeaderContent = ({ centerLogo }) => {

    const { rpdata } = useContext(GlobalDataContext)

    // estado del menu
    const [isOpen, setOpen] = useState(false)

    const [widthWindows, setwidthWindows] = useState(window.innerWidth)


    const sizeWindows = () => {
        setwidthWindows(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', sizeWindows)
        return () => {
            window.removeEventListener('resize', sizeWindows)
        }
    })

    return (
      <header>
        {rpdata?.simpleWidgets?.[3]?.activo ? null : (
          <section
            className={`flex items-center shadow-xl ${
              widthWindows > 560 ? "bg-transparent" : "bgGradient1"
            }`}
          >
            <div className="flex items-center justify-between w-full">
              <div className="w-70% hidden md:block">
                {centerLogo ? (
                  <div className="flex lg:gap-4 px-14">
                    {rpdata?.dbPrincipal?.phones.map((phone, index) => {
                      return (
                        <a
                          key={index}
                          href={`tel:+1${phone.phone}`}
                          className="flex items-center justify-center gap-2 text-[18px] text-black hover:text-sky-600 ease-in-out duration-500"
                        >
                          <ImPhone fontSize={"14px"} />
                          <span>
                            {phone.phone}
                            <span className="pl-2">{phone.name}</span>
                          </span>
                        </a>
                      );
                    })}
                  </div>
                ) : (
                  <MenuList classes={"w-full flex justify-start gap-5 px-14"} />
                )}
              </div>
              <div
                className={`p-5 md:p-10 w-full md:w-[30%] flex flex-col md:flex-row items-start justify-start md:justify-end gap-0 md:gap-5 ${
                  widthWindows > 560 ? "bgGradient1" : "bg-transparent"
                }`}
              >
                {rpdata?.dbSocialMedia?.redes.length > 0 ? (
                  <span className="capitalize font-semibold text-white">
                    Follow us On:{" "}
                  </span>
                ) : null}

                <IconsRedes
                  classes={"flex justify-center items-center gap-5"}
                />
              </div>
            </div>

            <div
              className={`md:hidden relative z-20 flex justify-center py-6 w-[20%] mx-auto bg-white`}
            >
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>

            {isOpen ? (
              <div className="fixed bg-[#222] z-20 top-0 left-0 h-screen w-full ease-in-out duration-700">
                <div
                  className={`md:hidden absolute right-0 top-1 z-30 flex justify-center py-6 w-[20%] mx-auto bg-white `}
                >
                  <Hamburger toggled={isOpen} toggle={setOpen} />
                </div>
                <div className="flex flex-col justify-center items-center h-full w-full">
                  <picture className="flex justify-center items-center">
                    <img
                      src={rpdata?.dbPrincipal?.logo}
                      alt="logo"
                      className="w-[50%]  mb-10"
                    />
                  </picture>
                  <div className="w-full">
                    <MenuList
                      classes={
                        "w-full  h-[55vh] overflow-y-auto text-center text-white font-semibold text-[19px] flex flex-col gap-y-5"
                      }
                    />
                  </div>
                  <div>
                    <spa className="capitalize font-semibold text-white pt-10">
                      Follow us On:{" "}
                    </spa>

                    <IconsRedes
                      classes={"flex justify-center items-center gap-5"}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </section>
        )}

        <div className="relative">
          <div className="flex justify-center items-center absolute top-5 right-0 left-0 z-10">
            <div className="flex justify-center items-center gap-5">
              <div className={centerLogo ? "hidden md:block" : "hidden"}>
                <MenuList
                  classes={"w-full flex justify-start gap-5 text-white"}
                  rangoItemsFin={3}
                />
              </div>
              <img
                src={rpdata?.dbPrincipal?.logo}
                alt="logo"
                className="w-full md:w-[30%]"
              />
              <div className={centerLogo ? "hidden md:block" : "hidden"}>
                <MenuList
                  classes={"w-full flex justify-start gap-5  text-white"}
                  rangoItemsInicio={3}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
}

export default HeaderContent